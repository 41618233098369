import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Layout, PageEnum } from "../components/layout"
import ContactSection from "../components/contact-section"

const ContactSectionStyled = styled(ContactSection)`
  margin-top: 80px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 57px;
  }
`

const ContactPage = () => (
  <Layout page={PageEnum.CONTACT}>
    <SEO pageTitle="Contact" />
    <ContactSectionStyled />
  </Layout>
)

export default ContactPage
